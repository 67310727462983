@font-face {
  font-family: 'Roboto';
  src:
      url('assets/fonts/Roboto-Regular.ttf') format('truetype'),
      url('assets/fonts/Roboto-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'RobotoLight';
  src:
      url('assets/fonts/Roboto-Light.ttf') format('truetype'),
      url('assets/fonts/Roboto-Light.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}
html, body {
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
  color: #59564f;
}
body::-webkit-scrollbar {
  width: 10px;
}
div {
  box-sizing: border-box;
}

body::-webkit-scrollbar-track {
  background-color: #666666;
}
body::-webkit-scrollbar-thumb {
  background-color: #222222;
  border-radius: 10px;
}

#root {
  width: 100%;
  height: 100%;
}
.wrapper {
  height: 100%;
  width: 100%;
}
.header_section {
  background-color: #050300;
  border-bottom: 1px solid rgba(138,115,67,0.5);
}
.header {
  min-height: 90px;
  background-color: #050300;
  padding: 0 1%;
}
.logo {
  margin: 0 30px 0 20px;
}
.logo #logo {
  display: block;
  height: 60px;
  line-height: 60px;
  padding: 15px 0;
}
.logo img {
  vertical-align: middle;
  max-height: 100%;
}
.second_section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  background-color: #292116;
  max-height: 789px;
  padding-bottom: 40px;
}
.second_section img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  width: 1176px;
}
.third_section {
  padding-top: 97px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  background-color: #f0ca78;
  position: relative;
}
.third_section a {
  color: #2d2512;
  font-size: 28px;
  font-family: 'RobotoLight';
  text-decoration: none;
}
.third_section:before {
  content:'';
  width: 70px;
  height: 70px;
  background-color: #f0ca78;
  display: block;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.fourth_section_parallax {
  height: 532px;
}
.fourth_section .title {
  font-size: 54px;
  line-height: 54px;
  font-family: 'RobotoLight';
  color: #2d2512;
  margin-bottom: 15px;
}
.fourth_section .description {
  font-size: 16px;
  line-height: 27px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}
.fourth_section .description span {
  margin-bottom: 15px;
}
.fourth_section .content {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  padding: 0 60px 85px 15px;
}
.fifth_section {
  padding: 70px 0;
}
.fifth_section > div {
  width: 33%;
  display: inline-block;
  vertical-align: middle;
}
.fifth_section .title {
  font-size: 54px;
  line-height: 54px;
  font-family: 'RobotoLight';
  color: #2d2512;
  text-align: right;
  padding-right: 40px;
}
.fifth_section .map {
  border-left: 3px solid #2d2512;
  border-right: 3px solid #2d2512;
  font-family: 'RobotoLight';
  padding-bottom: 37px;
  text-align: center;
}
.fifth_section .map img {
  max-width: 100%;
  height: auto;
}
.fifth_section .description {
  font-size: 16px;
  line-height: 27px;
  padding: 0 40px;
}
.sixth_section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  max-height: 496px;
}
.sixth_section .content {
  width: 50%;
  background-color: #f0ca78;
  padding: 4% 6.8% 3% 4%;
}
.sixth_section .content .title {
  font-size: 54px;
  line-height: 54px;
  font-family: 'RobotoLight';
  color: #2d2512;
  margin-bottom: 15px;
}
.sixth_section .content .sub_title {
  font-size: 21px;
  line-height: 25px;
  color: #a2762d;
  font-family: 'RobotoLight';
  margin-bottom: 75px;
}
.sixth_section .content .description {
  font-size: 15.5px;
  line-height: 27px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}
.sixth_section .content .description span {
  width: 50%;
}
.sixth_section .content .description span:first-child {
  margin-right: 30px;
}
.sixth_section .parallax_container {
  width: 50%;
}
.sixth_section_parallax {
  height: 496px;
}
.footer_section {
  height: 112px;
  background: #e6edf1 repeat-x bottom center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
@media screen and (max-width: 1440px) {
  .sixth_section .parallax_container img {
    object-fit: scale-down;
  }
  .fourth_section img {
    object-fit: scale-down;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .sixth_section {
    flex-direction: column;
    max-height: inherit;
  }
  .sixth_section .content, .sixth_section .parallax_container {
    width: 100%;
  }
  .sixth_section .parallax_container img {
    object-fit: initial;
  }
}
@media screen and (max-width: 768px) {
  .sixth_section {
    flex-direction: column;
    max-height: inherit;
  }
  .sixth_section .content, .sixth_section .parallax_container {
    width: 100%;
  }
  .fourth_section {
    background-color: #eeede8;
  }
  .fourth_section img {
    display: none;
  }
  .fourth_section .content {
    padding: 0 35px;
    width: 100%;
    background-image: url("./assets/images/lineimage_mobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .fifth_section > div {
    width: 100%;
    text-align: center !important;
  }
  .fifth_section .title  {
    margin-bottom: 25px;
    padding-right: 0;
  }
  .fifth_section .title br {
    display: none;
  }
  .fifth_section .map {
    border: none;
  }
  .sixth_section .content .description {
    flex-direction: column;
  }
  .sixth_section .content .description span {
    width: 100%;
    margin-bottom: 10px;

  }
  .sixth_section .content .sub_title {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 414px) {
  .sixth_section_parallax {
    background-image: url("./assets/images/missilecut2_mobile.png");
    background-size: cover;
    background-position: center center;
  }

}